body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: Roboto;
  src: url("./styles/RobotoSlab-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("./styles/Montserrat-Medium.ttf");
}
.roboto * {
  font-family: "Roboto", Menlo, Monaco, Consolas, "Courier New", monospace !important;
}

.montserrat * {
  font-family: "Montserrat", Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bg-f2f2f2 {
  background-color: #f2f2f2;
}
.react-date-picker__wrapper {
  border: none !important;
}
/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}*/

code,
p,
div,
tbody {
  font-family: "Montserrat", Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
thead {
  font-family: "Roboto", Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ReactModal__Overlay {
  z-index: 999;
  display: flex;
  background-color: #003366aa !important;
  padding: 3rem;
}

button:disabled {
  background-color: lightgrey !important;
}

input:invalid,
textarea:invalid {
  border: 1px solid lightsalmon;
}

.flipcard:hover > img {
  filter: opacity(0);
}
.flipcard:hover > div {
  filter: opacity(1);
}
.flipcard > div {
  filter: opacity(0);
}
.fc-event {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #73bae5; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #0d7bbf; /* color of the scroll thumb */
}
