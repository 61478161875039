.from-hidden {
  animation: fromHidden 150ms;
}
body {
  background: url("../images/fondo.jpg");
  background-repeat: repeat;
}

@keyframes fromHidden {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fa-star.checked {
  color: #0a85a9;
}
table th:first-child,
table td:first-child {
  margin-left: 12.5%;
}
table th:last-child,
table td:last-child {
  margin-right: 12.5%;
}

.bg-sf-backgroundimg {
  background-image: url("../images/fondo.jpg");
  background-repeat: repeat;
}

ul > .active > li {
  /*border-right: solid 0.4rem #1b6d87;*/
  /*background-color: #a8d3ee;*/
  background-color: #0d7bbf !important;
  color: white !important;
}

ul > .active > li > img {
  filter: invert();
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
